import { injectComponent } from '@mediashop/app/component-injector';
import Favicon from './Favicon';
import Theme from './Theme';
import StructuredData from './StructuredData';

/**
 * Render theme-specific meta-elements.
 */
function Meta() {
    return (
        <>
            <Favicon />
            <StructuredData />
            <Theme />
        </>
    );
}

export default injectComponent('pattern.template.Meta', Meta, 'proactiv');
