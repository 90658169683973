import { Helmet } from 'react-helmet-async';
import { injectComponent } from '@mediashop/app/component-injector';

/**
 * Theme-Color for Android Chrome.
 */
function Theme() {
    return (
        <Helmet>
            <meta name="theme-color" content="#00afc1" />
        </Helmet>
    );
}

export default injectComponent('pattern.template.Meta.Theme', Theme, 'proactiv');
